import React ,{ useEffect} from 'react';
import Navbar from './global-components/navbar-v2';
import Page_headerAS1Copy from './global-components/page-headeras1copy';

import WhyChooseUs from './section-components/why-choose-us-v2copy';
import WhyChooseUsAbout from './section-components/why-choose-usaboutcopy';
import $ from 'jquery';
import PortFolioDetailsAS3Copy from './section-components/portfolio-detailsas3copy';
import PortFolioOurHistory from './section-components/portfoliodetailsas3abouthistorycopy';

import AboutUpsideCta from './section-components/cta-v12AboutUpside';

import ServiceV7AS7Copy from './section-components/service-v7as7copy';
import Footer from './global-components/footer-v2';

const AboutPage = () => {
    useEffect(() => {
        if ($('.side-menu__block').length) {
          $('.side-menu__block').removeClass('active');
        } else {
          console.log("Does not Exist");
        }
      }, []);
   
    return <div>
       <Navbar />
        <Page_headerAS1Copy headertitle="Simplify payroll, unleash potential."/>
        <PortFolioDetailsAS3Copy />
        <WhyChooseUs />
        <WhyChooseUsAbout />
        <PortFolioOurHistory />
        <ServiceV7AS7Copy />
        <AboutUpsideCta />
        <Footer />
    </div>
}

export default AboutPage

