import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class BlogGridCopy extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    return (
      <section className="blog-grid  go-top">
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-1.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p1.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Olivia Parker</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Fundamentals for Growth in the Staffing and Recruiting Industry</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
			      {/* /.col-lg-4 col-md-6 */}
			      <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-3.jpeg" }alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p2.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Lucas Harrison</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">What Clients Really Want Staffing Companies to Know</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
			      
			      <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-5.jpeg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p4.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Hakila K. Dolika</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Innovative Ideas to Move Your Staffing Business Forward</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
			      <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-6.jpeg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p3.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Harper Turner</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Leave Great Voicemails for Potential Candidates</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
			      
			      <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-8.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p5.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Isabella Turner</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">The Workforce of the Future: Should You Buy, Rent, or Build?</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
			      <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-10.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p6.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Liam Bennett</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">How HR Can Remain Relevant in the New World of Work</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
				  <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-9.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p7.jpg"} className='blog-img_profile' alt={imagealt} />
			              <p>Aiden Thompson</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Tips to Conduct Video Interviews for Staffing Agencies and Recruiters</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>
				  <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-7.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p8.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>HNoah Anderson</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Techniques for Minimizing Candidate Dropout Before Day One</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
				  <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-2.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p9.jpg" }alt={imagealt} className='blog-img_profile'/>
			              <p>Sophia Mitchell</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Talent Empowered: Bridging the Gap with Retention, Sustainability, and the Independent Workforce</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>
				  <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-4.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p10.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Lily Harrison</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">Getting the Most Out of LinkedIn as a Recruiter Without Spending a Time</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>{/* /.col-lg-4 col-md-6 */}
				  <div className="col-lg-4 col-md-6">
			        <div className="blog-one__single">
			          <div className="blog-one__image">
			            <img src={publicUrl+"assets/images/homeimages/blog-11.jpg"} alt={imagealt} />
			          </div>{/* /.blog-one__image */}
			          <div className="blog-one__content">
			            <div className="blog-one__author">
			              <img src={publicUrl+"assets/images/blog/blog-p11.jpg"} alt={imagealt} className='blog-img_profile'/>
			              <p>Hakila K. Dolika</p>
			            </div>{/* /.blog-one__author */}
			            <h3><Link to="/blog-details">EOR Insight: Unveiling the Rise of Contingent Labor as the Next Big Thing in Workforce Management</Link></h3>
			            <Link to="/blog-details" className="thm-btn blog-one__btn">Read More <i className="fa fa-angle-double-right" /></Link>
			            {/* /.thm-btn */}
			          </div>{/* /.blog-one__content */}
			        </div>{/* /.blog-one__single */}
			      </div>
				
			    </div>{/* /.row */}
			
			  </div>{/* /.container */}
			</section>
    )
  }
}

export default BlogGridCopy;
