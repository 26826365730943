"use client"
import React, { Component } from 'react';


import { Link } from 'react-router-dom';

class NavbarV2 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
		

        return (
           <header className="site-header-two site-header-two__ go-top  go-top">
			  <nav className="main-nav__two stricky">
			    <div className="container">
			      <div className="main-nav__logo-box">
			        <Link to="/">
			          <img src={publicUrl+"assets/images/homeimages/darklogo2.png"} alt={ imgattr } className="banner-logo_small"/>
			        </Link>
			      </div>{/* /.main-nav__logo-box */}
			      <div className="main-nav__main-navigation">
			        <ul className=" main-nav__navigation-box ">
			          <li>
			            <Link to="/about" >
							<span >About</span></Link>
			          </li>
			          <li className="">
					  <Link to="/service">
						<span >Services</span></Link>
			          </li>
					  {/* <li className=" ">
			            
						<Link to="/portfolio-full">Knowledge</Link>
						</li>
			           
						<li className="">
			            <Link to="/blog-grid">Blog</Link></li>
			          */}
						{/* /.sub-menu */}
			          <li> 
			            <Link to="/contact" >
						<span className='myc' >Contact</span></Link>
			          </li>
			        </ul>
			      </div>{/* /.main-nav__main-navigation */}
			       <div className="main-nav__right">
			        <Link href="#/service" className="side-menu__toggler"><span /></Link>
			      </div> 
				  {/* /.main-nav__right */}
			    </div>{/* /.container */}
			  </nav>{/* /.main-nav__one */}
			</header>
        )
    }
}


export default NavbarV2