import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class VideoHS5Copy extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="video-one  go-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="video-one__box wow fadeInLeft" data-wow-duration="1500ms">
                  <img src={publicUrl+"assets/images/homeimages/bannerabout.jpeg"} alt={ imagealt } />
                  {/* <a href="#0" className="video-popup video-one__btn"><i className="fa fa-play" /></a> */}
                  {/* /.video-popup video-one__btn */}
                </div>{/* /.video-one__box */}
              </div>{/* /.col-lg-6 */}
              <div className="col-lg-6 d-flex">
                <div className="my-auto">
                  <div className="video-one__content">
                    <div className="block-title-two text-left">
                      <p>About Us</p>
                      <h3>Simplify your payroll, 
                    <br />Unleash your business potential.</h3>
                    </div>{/* /.block-title-two */}
                    <p>
The modern global workforce transformation, driven by technology, globalization, remote work, hybrid offices, and changing employee behavior, demands increased flexibility and mobility in today's workplace.
</p>
                    <ul className="list-unstyled video-one__list">
                      <li>
                        <i className="far fa-check" />
                        ITekera is a leading enabler of an on-demand mobile workforce
                      </li>
                      <li>
                        <i className="far fa-check" />
                        ITekera predicts growth with top EOR and AOR services, ensuring seamless global workforce solutions.
                      </li>
                      <li>
                        <i className="far fa-check" />
                        Exclusively designed for those who discover and offer talent solutions. 
                      </li>
                      
                      <li>
                        <i className="far fa-check" />
                        We make it straightforward, quick, low-cost, and risk-free.
                      </li>
                    </ul>
                    <Link to="/about" className="thm-btn">About us <i className="fa fa-angle-double-right" /></Link>
                    {/* /.thm-btn */}
                  </div>{/* /.video-one__content */}
                </div>{/* /.my-auto */}
              </div>{/* /.col-lg-6 */}
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>

        }
}

export default VideoHS5Copy