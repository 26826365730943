import React from 'react';
import CNavbar from './global-components/Cnavbar';
// import Banner from './section-components/banner';

import BannerCopy from './section-components/bannercopy';
import WhyChooseUs from './global-components/why-choose-us';
import VideoHS5Copy from './section-components/videohs5copy';
import AboutStyleOneHS5Copy from './section-components/about-style-onehs6copy';
import ServiceDetailsCopy from './section-components/service-detailshomecopy';

import Footer from './global-components/footer-v2';

const Home_V1 = () => {
    return <div>
       <CNavbar />
        <BannerCopy />
        <ServiceDetailsCopy />
        <WhyChooseUs />
        <AboutStyleOneHS5Copy />
        <VideoHS5Copy />
        <Footer />
    </div>
}

export default Home_V1

