import React , { useEffect } from 'react';
import $ from "jquery";
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import AboutService from './section-components/about-v4service';
import Footer from './global-components/footer-v2';
import ServicesContracting from './section-components/servicesContracting-style-two';
import ServicesProcurement from './section-components/servicesProcurement-style-two2';
import ServiceSearching from './section-components/service-v2Searching';
import ServiceV5Copy from './section-components/service-v5Copy';
const ServiceV1 = () => {
    useEffect(() => {
        if ($('.side-menu__block').length) {
          $('.side-menu__block').removeClass('active');
        } else {
          console.log("Does not Exist");
        }
      }, []);
    return <div>
       <Navbar />
        <PageHeader headertitle="Our focus is on serving the talent ecosystem"  Img="human-services.jpg" PositionClass="service_postionbanner"/>
        <ServiceV5Copy />
        <AboutService />
        <ServiceSearching />
        <ServicesProcurement />
        <ServicesContracting />
        <Footer />
    </div>
}

export default ServiceV1

