import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer-v2';
import BlogGridCopy from './blog-components/blog-gridcopy';

const BlogGrid = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Insights That Inspire. Explore our Engaging Blogs for the Fresh Perspective"  Img="diverse-friends.jpg" />
        <BlogGridCopy />
        <Footer />
    </div>
}

export default BlogGrid

