import React, { Component, } from "react";
class Page_headerAS1Copy extends Component {
  render() {
    let HeaderTitle = this.props.headertitle;
    let publicUrl = process.env.PUBLIC_URL + "/";
    // let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle;
    let Img = this.props.Img ? this.props.Img : "aboutbanner.png";

    return (
      <section
        className="page-header"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/images/backgrounds/" + Img + ")",
        }}
      >
        <div className="container">
          <h2>{HeaderTitle}</h2>
          <p className="text-light h5 mt-4">
          The evolving global workforce, driven by technology, globalization, remote work, and changing employee behavior, demands increased flexibility. iTekera facilitates this shift with comprehensive EOR and AOR services, enabling rapid, low-cost, risk-free growth and engagement with the global workforce
          </p>
        </div>
      </section>
    );
  }
}

export default Page_headerAS1Copy;
