import React, { Component } from 'react';


class Pportfoliodetailsas3abouthistorycopy extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="portfolio-details">
        <div className="container">
          <h3 className="portfolio-details__title">Our History
</h3>
          <br />
          <p>ITekera was founded in 2022, with a mission to simplify payroll and employment management for businesses of all sizes. As our company started to grow, we began to expand our offerings to include tax compliance, employee benefits administration, and human resource management. We aim to be leaders in the payroll and employment management industry, serving businesses across multiple industries and geographies. Recently, we also launched our Agent of Record (AOR) and Employer of Record (EOR) services, further expanding our services to help businesses manage employment compliance and minimize risk.</p>
          <br />
          <img src={publicUrl+"assets/images/homeimages/aboutourhistory.jpg"} alt={ imagealt } className="img-fluid" />
          <br />
          <br />
          <p>Today, ITekera provides services all over the United States, as well as India, with a major focus on comprehensive payroll and employee management services, and a team of experts dedicated to providing world-class support and guidance to all businesses. Our commitment to innovation, customer service, and compliance has earned us a reputation as a trusted partner for businesses seeking to simplify their payroll and employee management.</p>
          <br />
          {/* <h4>Project Info</h4> */}
          <br />
          {/* <div className="portfolio-details__box">
            <div className="portfolio-details__box-single">
              <h3>Project Name</h3>
              <p>Creative Design</p>
            </div>/}
            <div className="portfolio-details__box-single">
              <h3>Project Date</h3>
              <p>05 July 2019</p>
            </div>
            <div className="portfolio-details__box-single">
              <h3>Our Clients </h3>
              <p>Bc Design Studio</p>
            </div>
            <div className="portfolio-details__box-single">
              <h3>Location</h3>
              <p>850 Street, Chicago</p>
            </div>
            <div className="portfolio-details__box-single">
              <h3>End Date</h3>
              <p>10 July 2019</p>
            </div>
          </div> */}
          {/* /.portfolio-details__box-single */}{/* /.portfolio-details__box-single */}{/* /.portfolio-details__box-single */}
        </div>
      </section>


        }
}

export default Pportfoliodetailsas3abouthistorycopy;