import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CNavbar extends Component {
	
    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'
        let imgattr = 'logo'
		// const handleClick = () => {
		// 	document.querySelector('.side-menu__block').classList.remove('active');
		// 	console.log("remove");
		// 	history.push("/contact");
		//   };
        return (
           <header className="site-header-one go-top">
			  <nav className="main-nav__one stricky main_nav_left">
			    <div className="container-fluid">
			      <div className="main-nav__logo-box">
			        <Link to="/">
			          <img src={publicUrl+"assets/images/homeimages/lightlogo3.png" } alt={ imgattr } className='banner-logo_small'/>
			        </Link>
			      </div>{/* /.main-nav__logo-box */}
			      <div className="main-nav__main-navigation">
			        <ul className=" main-nav__navigation-box">
			        
			          <li >
			            <Link to="/about">
								About
								</Link>
			          </li>
			          <li >
			            <Link to="/service">Services</Link>
			          </li >
			          
			          {/* <li className="">
			            
						<Link to="/portfolio-full">Knowledge</Link>

			          </li>
			          <li className="">
			            <Link to="/blog-grid">Blog</Link>
			          
			          </li> */}
			          <li >
			            <Link to="/contact" > Contact</Link>
			          </li>
			        </ul>
			      </div>{/* /.main-nav__main-navigation */}
			      <div className="main-nav__right">
			        {/* <Link to="/contact" className="thm-btn my-button-visibility"> */}
						{/* <i className="fa fa-angle-right" /> */}
						<Link href="#" className="side-menu__toggler"><i className="fa fa-bars" />
						</Link>
						{/* </Link> */}
			      </div>
				  {/* /.main-nav__right */}{/* /.thm-btn */}
			    </div>
			  </nav>
			</header>
        )
    }
}


export default CNavbar