import React, { Component } from 'react';


class ServicesContracting extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-two  go-top">
		  <img src={publicUrl+"assets/images/shapes/about-two-bg.png"} alt={ imagealt } className="about-two__bg" />
		  <div className="container">
		    <img src={publicUrl+"assets/images/homeimages/consuliting1.png"} alt={ imagealt } className="about-two__moc" />
		    <div className="row">
		      <div className="col-lg-6">
		        <div className="about-two__content">
		          <div className="block-title text-left">
		            {/* <p><span>What We Do</span></p> */}
		            <h3>CONTRACTING, CONSULTING, <br/> AND COMPLIANCE
</h3>
		            <div className="block-title__line" />{/* /.block-title__line */}
		          </div>{/* /.block-title */}
		          <p>We'll help you outperform your clients on a large scale by allowing you to quickly and compliantly organize any kind of task for anyone, anywhere in the world.</p>
		          <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far fa-id-card" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Classify, Hire, and Pay</h3>
		              <p>With AOR/EOR services, ITekera ensures worry-free employee classification, legal employment, and payroll funding. Comprehensive solutions cover all worker types locally and globally</p>
		            </div>{/* /.about-two__box-content */}
		          </div>{/* /.about-two__box */}
		          <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far fa-arrow-up" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Grow Effectively
					</h3>           
					<p>Integrate ITekera for revenue growth, backend support, and strategic talent acquisition. Securely utilize contract workers with financing, benefits administration, and compliance solutions.</p>
		            </div>{/* /.about-two__box-content */}
		          </div>{/* /.about-two__box */}
				  
		        </div>{/* /.about-two__content */}
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        }
}

export default ServicesContracting;