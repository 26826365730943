import React, { Component } from 'react';


class AboutV4Service extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-four  go-top">
		  <div className="container">
		    <div className="row"> 
			<div className="col-lg-6">
		        <div className="about-four__content">
		          <div className="block-title-two text-left">
		            <h3>STAFFING</h3>
		          </div>{/* /.block-title-two text-left */}
		          <p>We offer outsourced support for your staffing needs, allowing you to concentrate on recruitment.
</p>
		          <div className="about-four__box-wrap">
		            <div className="about-four__box">
		              <div className="about-four__box-icon">
		                <i className="fal fa-search" />
		              </div>{/* /.about-four__box-icon */}
		              <div className="about-four__box-content">
		                <h3>Don't limit yourself - we've got you</h3>
		                <p>Our EOR empowers staffing agencies, extending reach, offering benefits through a third party, fostering expansion and compliance.

 
</p>
		              </div>{/* /.about-four__box-content */}
		            </div>{/* /.about-four__box */}
		            <div className="about-four__box">
		              <div className="about-four__box-icon">
		                <i className="fal  fa-user-secret" />
		              </div>{/* /.about-four__box-icon */}
		              <div className="about-four__box-content">
		                <h3>Secure Hiring</h3>
		                <p>iTekera excels in compliance, addressing all regulatory issues, ensuring full hiring process compliance for top talent acquisition.

</p>
		              </div>{/* /.about-four__box-content */}
		            </div>{/* /.about-four__box */}
		            <div className="about-four__box">
		              <div className="about-four__box-icon">
		                <i className="fal fa-puzzle-piece" />
		              </div>{/* /.about-four__box-icon */}
		              <div className="about-four__box-content">
		                <h3>Get Rid of Concerns</h3>
		                <p>
At iTekera, prioritize accurate, timely payments. Payroll funding for staffing advances up to 100%, ensuring worry-free, timely, full payments.</p>
		              </div>{/* /.about-four__box-content */}
		            </div>{/* /.about-four__box */}
		          </div>{/* /.about-four__box-wrap */}
		        </div>{/* /.about-four__content */}
		      </div>{/* /.col-lg-6 */}
			<div className="col-lg-6">
		        <img src={publicUrl+"assets/images/homeimages/staffing3da.jpeg"} className="about-four__moc" alt={ imagealt } />
		      </div>
		     
		      {/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default AboutV4Service;