import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceSearching extends Component {

    render() {

        // let publicUrl = process.env.PUBLIC_URL+'/'

    return	<section className="service-three  go-top">
		  <div className="container">
		    <div className="block-title-two text-center">
		      <h3>SEARCHING 
 <br /> &  <br />RECRUITING</h3>
			  <p>You can quickly and efficiently scale your business with our end-to-end solution and compete effectively with larger firms. Our team is always available to provide you with the guidance and support you require to remain compliant and successful in all aspects of your operations. We handle the back office allowing you to concentrate on recruiting.</p>

		    </div>{/* /.block-title-two text-center */}
		    <div className="row">
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-crosshairs" />
		          <h3><Link to="/service-details">Don't Restrict Yourself</Link></h3>
		          <p>As a search and recruiting firm, you understand the importance of being able to place contracts regardless of location, and of providing competitive benefits packages to attract and retain top talent. 
</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-lock" />
		          <h3><Link to="/service-details">Secure Hiring</Link></h3>
		          <p>At iTekera, our specialized compliance services tackle regulatory issues, freeing you to focus on talent. Best-in-class risk management ensures fully compliant hiring globally.</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    
		      <div className="col-xl-4 col-lg-6">
		        <div className="service-three__single">
		          <i className="fal fa-question" />
		          <h3><Link to="/service-details">Get Rid of Concerns</Link></h3>
		          <p>
At iTekera, we prioritize accurate, timely employee payments. Our payroll funding for staffing companies advances up to 100%, ensuring worry-free, on-time payments, every time.</p>
		        </div>{/* /.service-three__single */}
		      </div>{/* /.col-lg-3 col-md-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        }
}

export default ServiceSearching