import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import Portfolio from './section-components/portfolio-full';
import Footer from './global-components/footer-v2';

const PortfolioFull = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Unlock Insights and Expand Your Knowledge."  Img="boy-pointing-blackboard.jpg" PositionClass="postion-top-banner" />
        <Portfolio />
        <Footer />
    </div>
}

export default PortfolioFull

