import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceV7AS7Copy extends Component {

    render() {

    // let publicUrl = process.env.PUBLIC_URL+'/'
    // let imagealt = 'image'

    return	<section className="service-eight  go-top">
			  <div className="container">
			    <div className="block-title-two text-center">
			      <p>Why choose us</p>
			      <h3>Why Our Clients <br />  Choose Us?</h3>
			    </div>{/* /.block-title-two */}
			    <div className="service-eight__single">
			      <div className="service-eight__icon">
			        <i className="fal fa-heartbeat" />
					
			      </div>{/* /.service-eight__icon */}
			      <div className="service-eight__content">
			        <h3>Trust</h3>
			        <p>Our clients choose us because they trust us to handle their payroll and employee management needs with the utmost professionalism and care. We take this responsibility seriously and strive to exceed their expectations every day.</p>
			      </div>{/* /.service-eight__content */}
			      <div className="service-eight__btn-block">
			        
			      </div>{/* /.service-eight__btn-block */}
			    </div>{/* /.service-eight__single */}
			    <div className="service-eight__single">
			      <div className="service-eight__icon">
			        <i className="fal fa-handshake" />
			      </div>{/* /.service-eight__icon */}
			      <div className="service-eight__content">
			        <h3>Trusted Partnership</h3>
			        <p>Our clients see us as trusted partners who are committed to their success. They know that we are always looking out for their best interests and providing personalized support that goes beyond just managing their payroll and employee management.</p>
			      </div>{/* /.service-eight__content */}
			      <div className="service-eight__btn-block">
			       
			      </div>{/* /.service-eight__btn-block */}
			    </div>{/* /.service-eight__single */}
			    <div className="service-eight__single">
			      <div className="service-eight__icon">
			        <i className="fal fa-hourglass-half" />
			      </div>{/* /.service-eight__icon */}
			      <div className="service-eight__content">
			        <h3>Time-Saving</h3>
			        <p>By outsourcing their payroll and employee management needs to us, our clients can save valuable time that they can devote to other critical business tasks. They no longer have to worry about the time-consuming administrative tasks that come with managing payroll and employment compliance.</p>
			      </div>{/* /.service-eight__content */}
			      <div className="service-eight__btn-block">
			        
			      </div>{/* /.service-eight__btn-block */}
			    </div>
				<div className="service-eight__single">
			      <div className="service-eight__icon">
			        <i className="fal fa-chart-line" />
			      </div>{/* /.service-eight__icon */}
			      <div className="service-eight__content">
			        <h3>Scalability</h3>
			        <p> Our clients appreciate that we offer scalable solutions that can grow with their business. Whether they are small startups or large enterprises, we have the expertise and technology to meet their needs.</p>
			      </div>{/* /.service-eight__content */}
			      <div className="service-eight__btn-block">
			       
			      </div>{/* /.service-eight__btn-block */}
			    </div>{/* /.service-eight__single */}
			    <div className="service-eight__single">
			      <div className="service-eight__icon">
			        <i className="fal fa-universal-access" />
			      </div>{/* /.service-eight__icon */}
			      <div className="service-eight__content">
			        <h3>Accessibility</h3>
			        <p> Our clients appreciate that we are always accessible to them. They know they can count on us to provide timely and accurate support whenever they need it.</p>
			      </div>{/* /.service-eight__content */}
			      <div className="service-eight__btn-block">
			        
			      </div>{/* /.service-eight__btn-block */}
			    </div>{/* /.service-eight__single */}
			    <div className="service-eight__single">
			      <div className="service-eight__icon">
			        <i className="fal fa-lightbulb" />
			      </div>{/* /.service-eight__icon */}
			      <div className="service-eight__content">
			        <h3>Innovation</h3>
			        <p> We are committed to constantly innovating and improving our services to meet the evolving needs of our clients. Our clients appreciate that we are always looking for ways to make their lives easier and their businesses more successful</p>
			      </div>{/* /.service-eight__content */}
			      <div className="service-eight__btn-block">
			        
			      </div>{/* /.service-eight__btn-block */}
			    </div>{/* /.service-eight__single */}
			  </div>{/* /.container */}
			</section>

        }
}

export default ServiceV7AS7Copy