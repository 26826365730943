import React, { Component } from 'react';


class PortFolioDetailsAS3Copy extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="portfolio-details">
        <div className="container">
          <h3 className="portfolio-details__title">Introduction to ITekera
</h3>
          <br />
          <p>Welcome to the world of payroll and employment management made easy! At ITekera, we understand the challenges that come with managing payroll and employment compliance for your business. That's why we offer world-class AOR (Agent of Record) and EOR (Employer of Record) services to help businesses stay compliant and operate efficiently.
Our AOR services give businesses the freedom to manage their own payroll while ensuring compliance with local regulations. Our team of experts acts as a trusted advisor, providing guidance and support every step of the way. We handle all the compliance complexities so you can focus on growing your business.
Our EOR services provide businesses with a turnkey solution for employment and payroll management. We handle all the complications of hiring and employment, from onboarding to termination, while businesses focus on their core operations.</p>
          <br />
          <img src={publicUrl+"assets/images/homeimages/introductinoIteck.jpg"} alt={ imagealt } className="img-fluid" />
          <br />
          <br />
          <p>At ITekera, we believe that Payroll doesn't have to be complicated. That's why we use the latest technology and industry best practices to ensure that our AOR and EOR services are both compliant and efficient. Our secure platform and customizable solutions make payroll and employee management simple and easy.
We have a team of experts with years of experience in the industry. They stay up-to-date with the latest regulations and tax laws to ensure your business is always compliant. Our customer service is unparalleled, providing you with the support you need to ensure that your payroll and employment management runs smoothly.
We understand that every business is unique, which is why we offer tailored AOR and EOR solutions that fit your specific needs. And as your business grows, our solutions grow with you.
With our comprehensive reporting and advanced analytics, we provide the tools you need to manage your payroll and employee management with ease.</p>
          <br />
          {/* <h4>Project Info</h4> */}
          <br />
          {/* <div className="portfolio-details__box">
            <div className="portfolio-details__box-single">
              <h3>Project Name</h3>
              <p>Creative Design</p>
            </div>/}
            <div className="portfolio-details__box-single">
              <h3>Project Date</h3>
              <p>05 July 2019</p>
            </div>
            <div className="portfolio-details__box-single">
              <h3>Our Clients </h3>
              <p>Bc Design Studio</p>
            </div>
            <div className="portfolio-details__box-single">
              <h3>Location</h3>
              <p>850 Street, Chicago</p>
            </div>
            <div className="portfolio-details__box-single">
              <h3>End Date</h3>
              <p>10 July 2019</p>
            </div>
          </div> */}
          {/* /.portfolio-details__box-single */}{/* /.portfolio-details__box-single */}{/* /.portfolio-details__box-single */}
        </div>
      </section>


        }
}

export default PortFolioDetailsAS3Copy