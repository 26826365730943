import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class WhyChooseUs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="service-one go-top">
          <div className="container">
            <div className="block-title text-center">
              <p><span>Offer</span></p>
              <h3>Itekera offers</h3>
              <div className="block-title__line" />{/* /.block-title__line */}
            </div>{/* /.block-title */}
            <div className="row high-gutter  go-top ">
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                    <img src={publicUrl+"assets/images/shapes/profit-growth.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Tailored payroll solutions
</h3>
                      {/* <p>Payroll solutions</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>We offer tailored payroll solutions that are customized to meet the unique needs of each business. Our team works closely with clients to ensure that their payroll processes are efficient, effective, and compliant.
                </p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/technology.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Cutting-edge technology</h3>
                      {/* <p>Technology
</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Our company uses cutting-edge payroll technology to provide accurate and efficient payroll processing. Our platform is user-friendly and can be accessed from anywhere, making payroll and employee management simple and convenient.
</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
              <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/money.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Expert payroll team
                      </h3>
                      {/* <p>Payroll Team</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Our team of payroll experts has years of experience in the industry and is dedicated to providing exceptional service. They stay up-to-date with the latest regulations and tax laws to ensure your payroll is always compliant.
                  </p>
                </div>
              </div>
              </div>
              <div className="row high-gutter  go-top mt-5">
             
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/devaluation.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Affordable pricing
                      </h3>
                      {/* <p>Affordable pricing</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p> We offer competitive pricing options that are affordable for businesses of all sizes. We believe that payroll services should be accessible to everyone, which is why we offer transparent pricing with no hidden fees.
                  </p>
                </div>{/* /.service-one__single */}
              </div>
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/creativity.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Scalable solutions
                      </h3>
                      {/* <p>Solutions</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Our payroll services are scalable, which means that they can grow with your business. Whether you have a few employees or hundreds, we can provide a payroll solution that meets your needs.
                  </p>
                </div>{/* /.service-one__single */}
              </div>
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/seo-report.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Comprehensive reporting
                      </h3>
                      {/* <p>Reporting</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Our platform provides comprehensive reporting that gives you insights into your payroll expenses. This reporting can help you identify trends, track employee hours, and analyze your payroll data.
                  </p>
                </div>{/* /.service-one__single */}
              </div>
            </div>{/* /.row */}
           
            <div className="row high-gutter  go-top mt-5">
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/customer-service.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Exceptional customer service
                      </h3>
                      {/* <p>Customer service</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p> We pride ourselves on providing exceptional customer service to our clients. Our team is always available to answer questions and provide support, ensuring that your payroll runs smoothly.
                  </p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-4">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/reliability.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Secure and reliable
                      </h3>
                      {/* <p>Security</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p> We take the security of your payroll data seriously. Our platform uses advanced security measures to ensure that your data is safe and protected at all times. We also have backup systems in place to ensure that your payroll is always available when you need it.
                  </p>
                </div>{/* /.service-one__single */}
              </div>
            </div>
          </div>{/* /.container */}
        </section>

        }
}

export default WhyChooseUs