import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceV5Copy extends Component {

    render() {

    let publicUrl = process.env.PUBLIC_URL+'/'
    // let imagealt = 'image'

    return	<section className="service-two service-two__service-page  go-top">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-5">
		        <div className="service-two__block">
		          <div className="block-title-two text-left">
		            <p>Our Services</p>
		            <h3>How Can ITekera
                    <br />Assist You</h3>
		          </div>
		          <p>At ITekera, we understand that scaling your business and competing with larger firms can be a challenge. That's why we offer global employer of record (EOR) and agent of record (AOR) services to help you outsource back-office functions and enhance your efficiency.</p>
		        
		          <span className="thm-btn">View More <i className="fa fa-angle-double-right" /></span>
		        </div>{/* /.service-two__block */}
		      </div>{/* /.col-lg-5 */}
		      <div className="col-lg-7">
		        <div className="service-two__box-wrap">
		          <div className="row">
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image"  />
		                <div className="service-two__single-inner">
		                  <i className="fal fa-sitemap" />
		                  <h3>Categorization</h3>
		                  <p>Our EOR/AOR platform aids business growth, delegating back-office tasks. Accurate worker classification ensures compliance, avoiding costly mistakes. iTekera's IC compliance process facilitates confident hiring.
</p>
		                  <span  className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></span>
		                 
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image"  />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-hand-holding-usd" />
		                  <h3>Payroll &amp; Taxes</h3>
		                  <p>At iTekera, we specialize in comprehensive payroll services, from funding to tax compliance, covering various staffing scenarios globally. Compliance made easy with our experienced team, allowing you to focus on business growth.
</p>
		                  <span  className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></span>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image"  />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-plane" />
		                  <h3>Local &amp; International</h3>
		                  <p>As a staffing agency, you're aware of varying laws across jurisdictions. Navigating complexities, especially in multi-jurisdictional contracts, requires expertise. Ensuring full compliance with local regulations, especially for remote and global talent, is our expertise.
</p>
		                  <span  className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></span>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		            <div className="col-md-6">
		              <div className="service-two__single js-tilt">
		                <div className="service-two__single-image"  />
		                {/* /.service-two__single-image */}
		                <div className="service-two__single-inner">
		                  <i className="fal fa-snowflake" />
		                  <h3>Division of Workers</h3>
		                  <p>Incorrect staff categorization can lead to tax fraud, compensation loss, and significant penalties. Avoid classification errors; ensure accuracy with our unique IC categorization method for full-time staff, contractors, and freelancers.
</p>
		                  <span  className="service-two__link"><i className="fa fa-long-arrow-alt-right" /></span>
		                  {/* /.service-two__link */}
		                </div>{/* /.service-two__single-inner */}
		              </div>{/* /.service-two__single */}
		            </div>{/* /.col-md-6 */}
		          </div>{/* /.row */}
		        </div>{/* /.service-two__box-wrap */}
		      </div>{/* /.col-lg-7 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>

        }
}

export default ServiceV5Copy;