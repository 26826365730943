import React, { Component } from 'react';


class ServicesProcurement extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <section className="about-two  go-top">
		  <img src={publicUrl+"assets/images/shapes/about-two-bg.png"} alt={ imagealt } className="about-two__bg" />
		  <div className="container">
		    <img src={publicUrl+"assets/images/homeimages/procuremntimg.png"} alt={ imagealt } className="about-two__moc" />
		    <div className="row">
		      <div className="col-lg-6">
		        <div className="about-two__content">
		          <div className="block-title text-left">
		            {/* <p><span>What We Do</span></p> */}
		            <h3>PROCUREMENT OF MASS TALENT</h3>
		            <div className="block-title__line" />{/* /.block-title__line */}
		          </div>{/* /.block-title */}
		          <p>
Empower VMS, MSP, FMS, and RPO with global EOR/AOR services, enhancing offerings for your clients' worldwide operations.</p>
		          <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far fa-leaf" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Classify, Hire, and Pay</h3>
		              <p>
					  Effective management classifies, hires, pays, streamlining workflows for efficient decision-making in interconnected organizational processes
</p>
		            </div>{/* /.about-two__box-content */}
		          </div>{/* /.about-two__box */}
		          <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far  fa-user-plus" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Proper Hiring</h3>
		              <p>ITekera handles risk, regulations, and independent worker processes, freeing you to focus on talent procurement and contract allocation.
</p>
		            </div>{/* /.about-two__box-content */}
		          </div>
				  {/* /.about-two__box */}
				  <div className="about-two__box">
		            <div className="about-two__box-icon">
		              <i className="far fa-gopuram" />
		            </div>{/* /.about-two__box-icon */}
		            <div className="about-two__box-content">
		              <h3>Improve Your Productivity
</h3>
		              <p>Through complete back-office assistance and provider neutrality, you can rely on us and concentrate your efforts where they are most needed.
When working with private freelancers, we offer AOR services such as risk elimination and reimbursement.
</p>
		            </div>{/* /.about-two__box-content */}
		          </div>
		        </div>{/* /.about-two__content */}
		      </div>{/* /.col-lg-6 */}
		    </div>{/* /.row */}
		  </div>{/* /.container */}
		</section>


        }
}

export default ServicesProcurement