import React , { useEffect } from 'react';
import $ from "jquery";
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/contatusbanner';
import Contact from './section-components/contact-page';
import Footer from './global-components/footer-v2';

const ContactPage = () => {
    useEffect(() => {
        if ($('.side-menu__block').length) {
          $('.side-menu__block').removeClass('active');
        } else {
          console.log("Does not Exist");
        }
      }, []);
    return <div>
        <Navbar />
        <PageHeader headertitle="Reach Out and Engage, We're Here to Listen and Support Your Needs."  />
        <Contact />
        <Footer />
    </div>
}

export default ContactPage

