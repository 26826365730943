import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Page_header extends Component {

    render() {

        let HeaderTitle = this.props.headertitle;
        let publicUrl = process.env.PUBLIC_URL+'/'
        // let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
        let Img = this.props.Img;
        let PositionClass = this.props.PositionClass;

        return (
			<section className={`page-header ${PositionClass}`} style={{backgroundImage: 'url('+publicUrl+'assets/images/homeimages/'+Img+')'}}>
				  <div className="container">
				    <h2>{ HeaderTitle }</h2>
            <p className="text-light h5 mt-4">
            We provide global Employer of Record (EOR) and Agent of Record (AOR) services, connecting talent supply and demand. Whether you're an independent recruiter or a large-scale talent procurement company, our tailored capabilities help achieve business goals. Committed to your success, we operate under a strict non-compete policy, exclusively supporting your client relationships for optimal service
          </p>
				    {/* /.list-unstyled thm-breadcrumb */}
				  </div>{/* /.container */}
			</section>

        )
    }
}

export default Page_header