import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PortFolioFull extends Component {

    render() {

      let publicUrl = process.env.PUBLIC_URL+'/'
      let imagealt = 'image'

  return  <section className="portfolio-three  go-top">
      <div className="container-fluid">
        <div className="block-title-two text-center">
          <p>Specialized Insights to Deepen Your Understanding</p>
          <h3>Explore Our Knowledge<br />Base</h3>
        </div>{/* /.block-title-two */}
        <div className="row no-gutters ">
          <div className="col-md-4 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/keepinmind.jpeg" }alt={ imagealt } />
              </div>{/* /.portfolio-three__image */}
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Key Factors to Keep in Mind </Link></h3>
                  <p><Link to="/portfolio-details">When Handling Payroll .</Link><Link to="/portfolio-details">for International WorkersDesign</Link></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/unlockthepath.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Unlocking the Path to Success</Link></h3>
                  <p><Link to="/portfolio-details"> Four Essential Pillars for </Link><Link to="/portfolio-details">Growth in the Staffing and Recruiting Sector</Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
          <div className="col-md-4 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/navigationpath.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Navigating the Path to Growth</Link></h3>
                  <p><Link to="/portfolio-details">A Comprehensive Guide to</Link><Link to="/portfolio-details">Staffing and Recruiting Success</Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
          <div className="col-md-6 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/narrowingthegrowth.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Narrowing the Talent Gap</Link></h3>
                  <p><Link to="/portfolio-details">Empowering Talent Retention, Driving Sustainability,</Link><Link to="/portfolio-details">and Embracing the Independent Workforce</Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
          <div className="col-md-6 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/globalwork.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Erik Vonk Featured in Forbes Article on Global Worker Classification</Link></h3>
                  <p><Link to="/portfolio-details"></Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
          <div className="col-md-4 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/remoteservicer.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Ensuring the Security of Your Remote Workforce</Link></h3>
                  <p><Link to="/portfolio-details"></Link><Link to="/portfolio-details"></Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
          <div className="col-md-4 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/sixkeys.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Shaping the Future of Recruitment</Link></h3>
                  <p><Link to="/portfolio-details">Six Key Trends Redefining</Link><Link to="/portfolio-details"> the Industry</Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
          <div className="col-md-4 col-sm-12">
            <div className="portfolio-three__single">
              <div className="portfolio-three__image">
                <img src={publicUrl+"assets/images/homeimages/remotecollboration.jpeg"} alt="" />
              </div>
              <div className="portfolio-three__content">
                <div className="portfolio-three__content-inner">
                  <Link to="/portfolio-details" className="portfolio-three__link"><i className="fa fa-long-arrow-alt-right" /></Link>
                  <h3><Link to="/portfolio-details">Remote Collaboration</Link></h3>
                  <p><Link to="/portfolio-details">Bringing Teams Together While Apart</Link></p>
                </div>
              </div>
            </div>{/* /.portfolio-three__single */}
          </div>{/* /.col-md-4 col-sm-12 */}
        </div>{/* /.row no-gutters */}
      </div>
    </section>

      }
}

export default PortFolioFull