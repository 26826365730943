import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class WhyChooseUs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return  <section className="service-one go-top">
          <div className="container">
            <div className="block-title text-center">
              <p><span>Why Like Us</span></p>
              <h3>Success Your Business With <br />
                Establish Agency</h3>
              <div className="block-title__line" />{/* /.block-title__line */}
            </div>{/* /.block-title */}
            <div className="row high-gutter  go-top ">
              <div className="col-lg-6">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/target.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Staffing
</h3>
                      {/* <p>Staffing</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>Consider outsourcing your back-office administrative duties to us, enabling you to concentrate on your primary capabilities while converting your fixed costs to a variable model. With our employer of record payroll management, you can fuel your development even while ensuring recruitment agency compliance.
                </p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-6">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/seo.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Searching & Recruiting</h3>
                      {/* <p>Searching & Recruiting
</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>ITekera has the expertise and knowledge to ensure that all aspects of your staffing operations are completely consistent. Our extensive experience is reflected in the comprehensive coverage and back-office solutions we offer, as well as our in-depth knowledge of local regulations.
</p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              </div>
              <div className="row high-gutter  go-top mt-5">
              <div className="col-lg-6">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/agreement.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Mass Talent Procurement
                      </h3>
                      {/* <p>Procurement</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>We allow you—vendor management systems (VMS), managed service providers (MSP), and freelancer management systems (FMS) recruitment process outsourcers (RPO), —to extend the services you provide by offering worldwide EOR as well as AOR on behalf of your customers.
                  </p>
                </div>{/* /.service-one__single */}
              </div>{/* /.col-lg-4 */}
              <div className="col-lg-6">
                <div className="service-one__single">
                  <div className="service-one__top">
                    <div className="service-one__icon">
                      <img src={publicUrl+"assets/images/shapes/patent.png"} alt={ imagealt } />
                    </div>{/* /.service-one__icon */}
                    <div className="service-one__top-content">
                      <h3>Compliance, Contracting & Consulting
                      </h3>
                      {/* <p>Contracting & Consulting</p> */}
                    </div>{/* /.service-one__top-content */}
                  </div>{/* /.service-one__top */}
                  <p>We'll help you outperform your clients on a large scale by allowing you to quickly and compliantly organize any kind of task for anyone, anywhere in the world.
                  </p>
                </div>{/* /.service-one__single */}
              </div>
            </div>{/* /.row */}
          </div>{/* /.container */}
        </section>

        }
}

export default WhyChooseUs