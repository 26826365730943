import React, { Component } from "react";


class ServiceDetailsCopy extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="service-details">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 clearfix">
              <img
                src={publicUrl + "assets/images/homeimages/outgrowfitwidth.png"}
                alt={imagealt}
                className="float-right wow fadeInLeft"
                data-wow-duration="1500ms"
              />
            </div>
            {/* /.col-lg-6 */}
            <div className="col-lg-6">
              <div className="service-details__content">
                <h3>
                  Our goal is to facilitate
                  <br /> the growth of a dynamic andinnovative
                  <br /> industry cluster.
                </h3>
                <p>
                  You source a workforce for your customers. We expand your
                  business by providing new unified expertise to place anyone to
                  perform in any segment, in any destination,in any country, for any kind of position, for any time frame,
                  and in any currency.
                </p>
                <p>
                   We carry out all of this in strict
                  compliance with legal requirements. All of this increases
                  one's profitability by giving your customers direct exposure
                  to the world's global, portable, & remote stream of empowered
                  talent.
                </p>
                {/* <Link to="/service" className="service-details__link">
                  Continue Reading
                </Link> */}
                {/* /.service-details__link */}
              </div>
              {/* /.service-details__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
          {/* <div className="row">
			      <div className="col-lg-6">
			        <img src={publicUrl+"assets/images/resources/service-d-moc-2.png"} alt={ imagealt } className="wow fadeInRight" data-wow-duration="1500ms" />
			      </div>
			      <div className="col-lg-6">
			        <div className="service-details__content">
			          <h3>We Help To Grow Your <br /> Business Growth</h3>
			          <ul className="list-unstyled service-details__list">
			            <li>
			              Sed ut perspiciatis unde omnis iste natus error sit voluptatem acclor emquei laudantium,
			              totam rem aperiam, eaque ipsa quae ab illo inventore
			            </li>
			            <li>But I must explain to you how all this mistaken idea of denouncing plaerais
			              ing pain was born and I will give you a complete account of the syst expound the actual
			              teachings of the great explorer of the truth</li>
			            <li>Dccusamus et iusto odio dignissimos ducimus qui blanditiis prae oluptatum deleniti atque
			              corrupti quos dolores et quas molestia</li>
			          </ul>
			          <Link to="/service" className="service-details__link">Continue Reading</Link>
			        </div>
			      </div>
			    </div> */}
        </div>
      </section>
    );
  }
}

export default ServiceDetailsCopy;
