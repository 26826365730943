import React, { Component } from "react";


class AboutStyleOneHS5Copy extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";

    return (
      <section className="about-one  go-top">
        <div className="container">
          <img
            src={publicUrl + "assets/images/homeimages/out2.png"}
            alt={imagealt}
            className="about-one__moc"
          />
          <div className="row justify-content-end">
            <div className="col-lg-6">
              <div className="about-one__content">
                <div className="block-title text-left">
                  <p>
                    <span></span>
                  </p>
                  <h3>
                    The global workforce is evolving rapidly
                     Adapt<br />before it's too LATE.
                  </h3>
                  <div className="block-title__line" />
                  {/* /.block-title__line */}
                </div>
                {/* /.block-title */}
                <p>
                  The modern-day workplace has undergone a radical
                  transformation due to the rapid advancement of technology and
                  cultural changes. As a result, businesses need to adopt a
                  flexible, hybrid workforce that includes a diverse mix of
                  full-time and part-time employees, freelancers, and
                  independent contractors, both onsite and remote.
                </p>
                <p>
                  This shift has been further fueled by the growing demand for
                  work-life balance, mobility, and autonomy among workers.
                  Therefore, it's an ideal time to explore and harness the
                  immense potential of the global workforce.
                </p>
                {/* <Link to="/about" className="thm-btn about-one__btn">Read More <i className="fa fa-angle-double-right" /></Link> */}
                {/* /.thm-btn */}
              </div>
              {/* /.about-one__content */}
            </div>
            {/* /.col-lg-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    );
  }
}

export default AboutStyleOneHS5Copy;
